import React, { useState } from "react";
import Roshan from "../../static/Courses/Roshan.jpg";
import Alka from "../../static/Courses/Alka.jpg";
import Vishal from "../../static/Courses/Vishal.jpg";
import Soumya from "../../static/Courses/Somya.jpg";
import Priti from "../../static/Courses/Priti.jpg";
import peopleNew from "../../static/Random/people.png";
import trade from "../../static/Random/trade.png";
import thumb from "../../static/Random/thumb.png";
import Star from "../../static/Star.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import chatGPT from "../../static/Icons/chatGPT.png";
import python from "../../static/Courses/python.png";
import powreBI from "../../static/Courses/powreBI.png";
import mysql from "../../static/Courses/mysql.png";
import chatGPTNew from "../../static/Courses/chatGPTNew.png";
import SQL from "../../static/Courses/SQL.png";
import clsx from "clsx";
import { styled } from "@mui/system";
import ApplyNowModal from "../Courses/CommonApplyNowBtn";
import "./PythonInternship.css";
import {
  Grid,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  CardContent,
  Avatar,
  Card,
  Button,
} from "@mui/material";

const ProgramRatings = ({
  border,
  courseName,
  GPTBoxColor,
  GPTImgColor,
  borderImageColor,
  ButtonColor,
}) => {
  const CardData = ({ data }) => {
    const { name, rating, icon } = data;
    return (
      <div
        className={` bg-[#14171e] w-[300px] flex items-center  p-3 space-x-4 rounded-xl md:w-[250px] border-[${border}] border`}
        style={{ border: `1px solid ${border}` }}
      >
        <img src={icon} alt="" className="w-[70px] h-[70px] rounded-full" />
        <div>
          <p>{name}</p>
          <div className="flex items-center space-x-2">
            <p>{rating}</p>
            <img
              src={Star}
              alt=""
              className="w-[20px] h-[19px] object-contain"
            />
          </div>
        </div>
      </div>
    );
  };

  const [show, setShow] = useState(false); // Correct usage of useState
  return (
    <div className="mb-28">
      <Box
        sx={{
          backgroundColor: "black",
          padding: { xs: "20px 10px", md: "50px 0" }, // Adjust padding for mobile
          textAlign: "center",
        }}
      >
        {/* Main Heading */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { xs: "24px", md: "32px" }, // Smaller font for mobile
            lineHeight: { xs: "1.5", md: "normal" }, // Adjust line height for mobile
          }}
        >
          Learn and Navigate through the future - use AI to your advantage
        </Typography>

        {/* Card Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <Card
            sx={{
              // background: "linear-gradient(180deg, #3B2C10 50%, #000000 100%)",
              background: GPTBoxColor,
              color: "white",
              width: { xs: "100%", md: "850px" }, // Full width for mobile
              padding: { xs: "20px", md: "50px" }, // Adjust padding for mobile
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" }, // Stack content vertically on mobile
              height: { xs: "auto", md: "450px" }, // Auto height for mobile
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: { xs: "column", md: "row" }, // Stack icon and text vertically on mobile
                padding: { xs: "0", md: "10px" }, // Adjust padding for mobile
              }}
            >
              {/* Icon on the left */}
              <Box
                sx={{
                  width: { xs: "120px", md: "250px" }, // Smaller width on mobile
                  height: { xs: "120px", md: "150px" },
                  // backgroundColor: GPTImgColor,
                  // backgroundColor: "#614927",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: { xs: "0", md: "20px" }, // Remove right margin on mobile
                  marginBottom: { xs: "20px", md: "0" }, // Add bottom margin on mobile
                }}
              >
                <img
                  src={chatGPT}
                  alt="AI Icon"
                  style={{ width: "2000px", height: "auto" }}
                />
              </Box>

              {/* Text Content on the right */}
              <Box
                sx={{
                  textAlign: "left",
                  marginLeft: { xs: "0", md: "200px" }, // Remove left margin for mobile
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Exclusive Module on Leveraging AI & ChatGPT for {courseName}{" "}
                  {""}
                  Programming:
                </Typography>
                <List sx={{ color: "white", fontSize: "12px" }}>
                  <ListItem>
                    • Understand ChatGPT and Become a Master Prompter
                  </ListItem>
                  <ListItem>• Gen AI for all development roles</ListItem>
                  <ListItem>
                    • How Gen AI can help you in software development
                  </ListItem>
                  <ListItem>
                    • How Gen AI can assist {courseName} Programmers
                  </ListItem>
                </List>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>

      <hr
        style={{
          color: "white",
          width: "200px",
          marginBottom: "100px",
          marginTop: "60px",
          marginLeft: "700px",
        }}
      />

      <Box
        sx={{
          marginTop: "30px",
          background: GPTBoxColor, // Dark gradient background
          color: "white",
          padding: { xs: "10px 0", md: "20px 0" }, // Smaller padding for mobile
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column", // Stacks content in a column
        }}
      >
        {/* Title */}
        <Typography
          variant="h5"
          sx={{
            fontSize: { xs: "20px", md: "24px" }, // Smaller font size for mobile
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Learn to use these tools effectively
        </Typography>
        <br />

        {/* Images Section */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap", // Allows wrapping in smaller screens
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" }, // Stack images vertically on mobile
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={python}
              alt="Python"
              width="100px"
              height="40px"
              style={{ margin: "10px" }}
            />
            <img
              src={powreBI}
              alt="PowerBI"
              width="100px"
              height="40px"
              style={{ margin: "10px" }}
            />
            <img
              src={chatGPTNew}
              alt="ChatGPT"
              width="40px"
              height="40px"
              style={{ margin: "10px" }}
            />
            <img
              src={mysql}
              alt="MySQL"
              width="80px"
              height="40px"
              style={{ margin: "10px" }}
            />
            <img
              src={SQL}
              alt="SQL"
              width="40px"
              height="40px"
              style={{ margin: "10px" }}
            />
          </Box>
        </Box>
      </Box>

      <hr
        style={{
          color: "white",
          width: "200px",
          marginBottom: "100px",
          marginTop: "60px",
          marginLeft: "700px",
        }}
      />

      <Box
        sx={{
          backgroundColor: "black",
          padding: "50px 0",
          textAlign: "center",
        }}
      >
        {/* Main Heading */}
        <p className="text-4xl font-semibold md:text-3xl">
          Get life-time access to an
          <br />
          exclusive community
        </p>

        {/* Subheading Text */}
        <Typography
          variant="body2"
          sx={{
            color: "#A0A0A0",
            marginBottom: "40px",
            marginTop: "40px",
            fontSize: "13px",
          }}
        >
          Of like-minded people looking to build, accelerate or enhance their
          existing careers by learning strategic
          <br />
          the door as a {courseName} Programmer.
        </Typography>

        <Box
          sx={{
            backgroundColor: "#000",
            minHeight: "50vh",
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={3}>
              <CardWrapper
                sx={{
                  backgroundColor: "#000",
                  height: "220px",
                  width: { xs: "350px", md: "280px" },
                  border: "1px solid",
                  borderImage: borderImageColor,
                }}
              >
                <IconWrapper>
                  <i
                    className="fas fa-users"
                    style={{ color: "#fff", width: "25px" }}
                  >
                    <img src={peopleNew} alt="people" />
                  </i>
                </IconWrapper>
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    Collaborate on real-life projects
                  </Typography>
                  <p style={{ fontSize: "12px" }}>
                    Meet and learn with others pursuing the same path as you.
                  </p>
                </CardContent>
              </CardWrapper>
            </Grid>

            <Grid item xs={12} md={3}>
              <CardWrapper
                sx={{
                  backgroundColor: "#000",
                  border: "1px solid",
                  borderImage: borderImageColor,
                  height: "220px",
                  width: { xs: "350px", md: "280px" },
                }}
              >
                <IconWrapper>
                  <i
                    className="fas fa-users"
                    style={{ color: "#fff", width: "25px" }}
                  >
                    <img src={trade} alt="trade" />
                  </i>
                </IconWrapper>
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    Community Accountability
                  </Typography>
                  <p style={{ fontSize: "12px" }}>
                    Growthschool fellows collaborate on projects, find
                    opportunities to learn and grow together while keeping each
                    other accountable.
                  </p>
                </CardContent>
              </CardWrapper>
            </Grid>

            <Grid item xs={12} md={3}>
              <CardWrapper
                sx={{
                  backgroundColor: "#000",
                  border: "1px solid",
                  borderImage: borderImageColor,
                  height: "220px",
                  width: { xs: "350px", md: "280px" },
                }}
              >
                <IconWrapper>
                  <i
                    className="fas fa-users"
                    style={{ color: "#fff", width: "25px" }}
                  >
                    <img src={thumb} alt="thumb" />
                  </i>
                </IconWrapper>
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    Get ALL your questions answered on LIVES
                  </Typography>
                  <p style={{ fontSize: "12px" }}>
                    Participate in live Q&As, debates, and other healthy
                    contests to drive home the learning.
                  </p>
                </CardContent>
              </CardWrapper>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            size="large"
            sx={{
              background: ButtonColor,
              color: "#000",
              padding: "18px 40px",
              borderRadius: "10px",
              width: "400px",
              fontWeight: "bold",
              textTransform: "none",
              display: { xs: "none", sm: "inline" },
            }}
            onClick={() => setShow(true)}
          >
            APPLY NOW
          </Button>
          <p className="applyButtonText">
            {" "}
            In the last 10 hours, 6 learners have onboarded with us
          </p>
        </Box>

        <hr
          style={{
            color: "white",
            width: "200px",
            marginTop: "60px",
            marginLeft: "700px",
            textAlign: "center",
          }}
        />
      </Box>
      <ApplyNowModal show={show} setShow={setShow} />
    </div>
  );
};

const CardWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: "#1e1e1e", // Dark background like the one in the image
  color: "#fff",
  borderRadius: "16px",
  padding: theme.spacing(2),
  boxShadow: "0px 4px 10px rgba(0,0,0,0.5)",
  border: "1px solid rgba(255,255,255,0.1)",
  textAlign: "center",
  "&:hover": {
    transform: "scale(1.05)",
    transition: "0.3s",
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#a9713e", // Icon background color similar to the one in the image
  borderRadius: "50%",
  width: "50px",
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto 20px",
}));

export default ProgramRatings;
