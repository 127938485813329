import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { Cancel } from "@mui/icons-material";
import React, { useState } from "react";
import {
  Modal,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

const ApplyNowModal = ({ show, setShow }) => {
  // Hooks are correctly placed here in the functional component
  const [interest, setInterest] = useState("");
  const [designation, setDesignation] = useState("");

  const phoneRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        phone: "",
        clgName: "",
        exp: "",
        companyName: "",
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required("Name is required"),
        phone: Yup.string()
          .matches(phoneRegex, "Invalid phone number")
          .required("Phone is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        clgName: Yup.string().required("College / Company Name is required"),
        exp: Yup.string().required("Year of graduation/Experience is required"),
      }),
      onSubmit: async (values) => {
        const payload = {
          ...values,
          interest: interest,
          designation: designation,
        };
        try {
          await axios.post(
            "https://maangler-backend.vercel.app/applyNow",
            payload
          );
          toast.success("Form submitted successfully");
        } catch (err) {
          toast.error(err.message);
        }
        resetForm();
      },
    });

  return (
    <Modal open={show} sx={{ zIndex: 9999999999 }} style={{ overflow: "auto" }}>
      <div className="p-10 mb:p-2 border-none outline-none overflow-y-auto">
        <div className="border p-6 shadow-2xl rounded w-full bg-white max-w-[1000px] mx-auto overflow-auto">
          <div onClick={() => setShow(false)}>
            <Cancel
              sx={{ marginLeft: "auto", display: "block", cursor: "pointer" }}
            />
          </div>
          <p className="text-3xl text-primary font-semibold text-center mb-6">
            Apply Now
          </p>
          <div className="space-y-10">
            <div>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Are you a College Student, Working Professional or Graduated
                  but not working? *
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  onChange={(e) => {
                    setDesignation(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="College Student"
                    control={<Radio />}
                    label="College Student"
                  />
                  <FormControlLabel
                    value="Working Professional"
                    control={<Radio />}
                    label="Working Professional"
                  />
                  <FormControlLabel
                    value="Graduated but not working"
                    control={<Radio />}
                    label="Graduated but not working"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="grid grid-cols-2 gap-6 mt-4 sm:grid-cols-1">
              {formData.map((item) => (
                <TextField
                  key={item.name}
                  label={item.label}
                  variant="outlined"
                  required={true}
                  error={Boolean(errors[item.name]) && touched[item.name]}
                  helperText={
                    Boolean(errors[item.name]) && touched[item.name]
                      ? errors[item.name]
                      : ""
                  }
                  onChange={handleChange}
                  name={item.name}
                  value={values[item.name]}
                />
              ))}
            </div>
          </div>
          <button
            onClick={handleSubmit}
            className="mt-6 bg-primary text-white w-[80%] mx-auto block py-2 rounded"
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};
const formData = [
  { id: 1, name: "name", label: "Candidate Full Name" },
  { id: 2, name: "email", label: "Email id " },
  { id: 3, name: "phone", label: "Phone Number" },
  { id: 4, name: "clgName", label: "College / Company Name" },
  { id: 5, name: "exp", label: "Year of graduation/Experience" },
];

export default ApplyNowModal;
