import React from "react";
import Background from "../static/fullstack/Background.jpg";
import HeroSection from "../components/Courses/HeroSection";
import MentorsStrip from "../components/PythonInternshipProgram/MentorsStrip";
import ProgramRatings from "../components/PythonInternshipProgram/ProgramRatings";
import MentorsSections from "../components/PythonInternshipProgram/MentorsSection";
import CourseInfo from "../components/Courses/CourseInfo";

//  linear-gradient(180deg, #42b4f4 -94.9%, rgba(0, 0, 0, 0.7) 108.25%)

const FullstackInternship = () => {
  return (
    <div
      style={{
        backgroundColor: "#000",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="text-white pb-20 overflow-hidden"
    >
      <HeroSection
        ButtonColor={
          "linear-gradient(91.73deg, #D0FFEE -70.05%, #5FCDE5 93.37%)"
        }
        InstructorBgColor={
          " linear-gradient(138.1deg, rgba(77, 142, 142, 0.8) 7.23%, rgba(35, 96, 122, 0.8) 56.17%)"
        }
        instrctorNameBgColor={
          "linear-gradient(180deg, #42b4f4 -94.9%, rgba(0, 0, 0, 0.7) 108.25%)"
        }
        courseName={FullStackCourseName}
        CourseDuration={FullStackCourseDuration}
        title={"Full Stack Development"}
        courseDescription={fullStackCourseDes}
        applicationDeadLine={
          "linear-gradient(91.74deg, rgba(77, 142, 142, 0.8) 16.87%, rgba(35, 96, 122, 0.8) 93.38%)"
        }
        broachrLink={broachrLink}
      />
      <MentorsStrip
        borderImageColor={"linear-gradient(90deg, #F9F2D0 0%, #ECA877 100%) 1"}
        ButtonColor={
          "linear-gradient(91.73deg, #D0FFEE -70.05%, #5FCDE5 93.37%)"
        }
        instrctorNameBgColor={
          "linear-gradient(180deg, #42b4f4 -94.9%, rgba(0, 0, 0, 0.7) 108.25%)"
        }
        courseName={FullStackCourseName}
        CourseDuration={FullStackCourseDuration}
        SuccessStoryBgColor={"#14181D"}
        SuccessStoryBoxShadoColor={
          "0px 3.13979px 11.7742px rgba(87, 76, 76, 0.15)"
        }
        SuccessStoryTextColor={"#26617A"}
      />
      <CourseInfo
        data={data}
        border={"#FAFF07"}
        courseName="Full Stack Web Development"
        CourseDuration={FullStackCourseDuration}
        CourseInfo1={modules}
        CourseInfo2={modules2}
        ButtonColor={
          "linear-gradient(91.73deg, #D0FFEE -70.05%, #5FCDE5 93.37%)"
        }
      />
      <ProgramRatings
        border={"#FAFF07"}
        courseName={FullStackCourseName}
        ButtonColor={
          "linear-gradient(91.73deg, #D0FFEE -70.05%, #5FCDE5 93.37%)"
        }
        GPTBoxColor={
          " linear-gradient(180deg, #42b4f4 -94.9%, rgba(0, 0, 0, 0.7) 108.25%)"
        }
        GPTImgColor={"rgba(120, 202, 236, 0.4)"}
        borderImageColor={"linear-gradient(90deg, #F9F2D0 0%, #ECA877 100%) 1"}
      />
      <MentorsSections
        courseName={FullStackCourseName}
        CourseDuration={FullStackCourseDuration}
        GPTBoxColor={
          " linear-gradient(180deg, #42b4f4 -94.9%, rgba(0, 0, 0, 0.7) 108.25%)"
        }
        ButtonColor={
          "linear-gradient(91.73deg, #D0FFEE -70.05%, #5FCDE5 93.37%)"
        }
        BonusBgColor={"linear-gradient(360deg, #192e3a 70%, #000000 100%)"}
        BonusCardBgColor={"rgba(206, 207, 210, 0.05)"}
        accordionBgColor={
          "linear-gradient(91.74deg, rgba(50, 111, 111, 0.8) 16.87%, rgba(21, 68, 87, 0.8) 93.38%)"
        }
      />
    </div>
  );
};

const FullStackCourseName = "Full Stack Web Development";
const FullStackCourseDuration = "16";
const broachrLink =
  "https://drive.google.com/file/d/1xWrqHnBzw7vsxYm4b-X_388e888wudIs/view?usp=sharing";

const data = {
  "Month 1": [
    {
      title: "Week 1-2",
      des: "Introduction to Web Development and HTML/CSS Basics",
      info: [
        "Introduction to Web Development and Web Technologies",
        "HTML5: Structure, Elements, and Semantics",
        "CSS3: Styling, Selectors, Box Model",
      ],
    },
    {
      title: "Week 3-4",
      des: "Advanced CSS and Responsive Design",
      info: [
        "CSS Layouts: Flexbox and CSS Grid",
        "Media Queries and Responsive Design Principles",
        "CSS Preprocessors (e.g., SASS)",
      ],
    },
    {
      title: "",
      des: "",
      info: [
        "Minor Project: Personal Portfolio Website. Create a personal portfolio website using HTML and CSS. Showcase your projects, skills, and a responsive design.",
        "Major Project: Landing Page with Responsive Design. Build a landing page for a fictional product or service. Apply advanced CSS techniques and ensure responsiveness across devices.",
      ],
    },
  ],
  "Month 2": [
    {
      title: "Week 5-6",
      des: "Introduction to Back-End and Node.js Basics",
      info: [
        "Introduction to Back-End Development and Server-side Concepts",
        "Setting Up Node.js Environment",
        "Building a Basic Server with Node.js and Express.js",
      ],
    },
    {
      title: "Week 7-8",
      des: "Databases and RESTful APIs",
      info: [
        "Introduction to Databases: SQL and NoSQL",
        "Working with MongoDB (or other NoSQL databases)",
        "Building RESTful APIs with Express.js",
      ],
    },
    {
      title: "",
      des: "",
      info: [
        "Minor Project: To-Do List Web App. Create a to-do list web application using Node.js, Express.js, and MongoDB. Implement CRUD operations for tasks.",
        "Major Project: Blogging Platform. Develop a blogging platform with user registration, authentication, and the ability to create, edit, and delete blog posts.",
      ],
    },
  ],
  "Month 3": [
    {
      title: "Week 9-10",
      des: "Connecting Front-End with Back-End",
      info: [
        "Building API Endpoints for Front-End Interaction",
        "Next 20 Days Major Projects.",
        "Using Templating Engines (e.g., EJS)",
      ],
    },
    {
      title: "Week 11-12",
      des: "Deployment & Advanced Concepts",
      info: [
        "Deployment Strategies: Heroku, Netlify, AWS, etc.",
        "Server-Side Rendering vs. Client-Side Rendering",
        "Introduction to Authentication and Security Practices",
      ],
    },
    {
      title: "",
      des: "",
      info: [
        "Minor Project: E-commerce Product Catalog. Develop an e-commerce product catalog with search functionality, using both front-end and back-end technologies.",
        "Major Project: Social Media Platform. Build a social media platform allowing users to post, like, comment, and follow other users. Implement authentication and real-time updates.",
      ],
    },
  ],
};

const modules = [
  {
    id: 1,
    title: "Introduction and Setup",
    description:
      "Gain a foundational understanding of Full Stack Web Development. Learn about the different technologies involved and set up the development environment.",
  },
  {
    id: 3,
    title: "Advanced HTML and CSS Basics",
    description:
      "Dive deeper into HTML topics such as forms, tables, and multimedia. Learn CSS basics including selectors, properties, and the box model.",
  },
  {
    id: 5,
    title: "Advanced JavaScript Concepts",
    description:
      "Understand scope, hoisting, and asynchronous programming with callbacks. Learn about promises and simplifying asynchronous code with rest parameters and spread syntax.",
  },
  {
    id: 7,
    title: "React.js Fundamentals",
    description:
      "Set up a basic React project. Leam React hooks, managing state and props, client-side routing, and using Context API for state management. Explore CSS modules and styled-components.",
  },
  {
    id: 9,
    title: "MongoDB and Database Management",
    description:
      "Set up MongoDB locally or on the cloud. Learn CRUD operations, indexing, and aggregation in MongoDB.",
  },
  {
    id: 11,
    title: "Integrating Frontend with Backend",
    description:
      "Fetch data using Fetch API or Axios, and send data via HTTP requests (POST, PUT, DELETE). Integrate frontend and backend seamlessly.",
  },
  {
    id: 13,
    title: "Deploying Applications",
    description:
      "Deploy the application to a cloud platform (e.g.. Heroku, Vercel). Learn performance optimization, SEO best practices, and preparing for production.",
  },
  {
    id: 15,
    title: "Capstone Project 2 (Unguided Project)",
    description:
      "Solidify your learning with an unguided project. Conduct an end- to-end project to demonstrate your full stack web development skills.",
  },
];

const modules2 = [
  {
    id: 2,
    title: "Git and HTML Fundamentals",
    description:
      "Overview of frontend and backend technologies, setting up Node.js. npm, and Visual Studio Code. Learn version control with Git and GitHub, and the basics of HTML.",
  },
  {
    id: 4,
    title: "CSS Frameworks and JavaScript Basics",
    description:
      "Introduction to CSS frameworks like Bootstrap for responsive design. Learn JavaScript basics including DOM manipulation. handling events, and ES6 syntax.",
  },
  {
    id: 6,
    title: "Mini Project and Asynchronous JavaScript",
    description:
      "Work with data and visualize it effectively. This module covers reading and writing CSV and JSON files, data visualization techniqApply JavaScript knowledge in a small-scale project. Create and handle promises, and manage asynchronous code effectively.ues, and basics of web scraping.",
  },
  {
    id: 8,
    title: "Backend Development with Node.js and Express.js",
    description:
      "Set up a Node.js server, create routes, handle requests and responses. Implement middleware for logging, error handling, and more.",
  },
  {
    id: 10,
    title: "Mongoose ODM",
    description:
      "Define schemas and models, perform data validation, and manage relationships using Mongoose ODM.",
  },
  {
    id: 12,
    title: "Error Handling, Validation, and Static Assets",
    description:
      "Handle errors gracefully, validate user input, manage file uploads, and serve static files in Express.js.",
  },
  {
    id: 14,
    title: "Capstone Project 1 (Instructor-led Guided Project)",
    description:
      "Apply your skills in a guided capstone project, implementing what you've learned throughout the course.",
  },
  {
    id: 16,
    title: "Conclusion and Career Preparation",
    description:
      "Apply your skills in a guided capstone project, implementing what you've learned throughout the course.",
  },
];


const fullStackCourseDes = (
  <p className="text-center mb-10 mt-8 relative z-50 mb:text-sm">
    Unveil the world of Full Stack Development through Maangler's immersive
    internship program. Tailored to fuel your passion{" "}
    <br className="lg:hidden" /> and launch your career, this program is your
    gateway to becoming a proficient <br className="lg:hidden" /> Full Stack
    Developer.
  </p>
);

export default FullstackInternship;
