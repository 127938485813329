import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../utils/Constants";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

/* The `loginAsync` function is a Redux async thunk action creator. It is used to handle the
asynchronous login process in the application. */
export const loginAsync = createAsyncThunk(
  "auth/login",
  async (credentials, thunkAPI) => {
    try {
      const response = await fetch(
        `https://cors-anywhere.herokuapp.com/${BASE_URL}/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(credentials),
        }
      );

      const data = await response.json();

      if (response.status === 201 || response.status === 200 || response.ok) {
        toast.success("Login successfully");
        return data;
      } else if (response.status === 409) {
        toast.error("User does not exist");
        throw new Error("Network Error");
      } else if (response.status === 403) {
        const newResponse = await fetch(`${BASE_URL}/refresh`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const newResponseData = await newResponse.json();
        const returnData = {
          user: thunkAPI.getState().auth.user,
          token: newResponseData,
        };
        return returnData;
      } else {
        toast.error("Network Error");
        throw new Error("Network Error"); // Reject the promise for non-specific errors
      }
    } catch (error) {
      console.error("An error occurred:", error);
      throw error; // Reject the promise for caught errors
    }
  }
);

/* The `registerNewUser` function is a Redux async thunk action creator. It is used to handle the
asynchronous registration process in the application. */
export const registerNewUser = createAsyncThunk(
  "auth/register",
  async (credentials, thunkAPI) => {
    const response = await fetch(`${BASE_URL}/auth/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentials),
    });
    const profileData = await response.json();
    if (response.status === 201 || response.status === 200) {
      const otpResponse = await fetch(`${BASE_URL}/otp/email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: credentials.email }),
      });
      if (otpResponse.status === 409) {
        toast.error("User already exists please login");
      } else if (response.status === 500) {
        toast.error("Network error");
      } else {
        toast.error("Some error occured");
      }
      toast.success("otp sent successfully");
      return profileData;
    } else if (response.status === 409) {
      toast.error("User already exists please login");
    } else if (response.status === 500) {
      toast.error("Network error");
    } else {
      toast.error("Some Error Ocurred");
    }
  }
);

/* The `socialLogin` function is a Redux async thunk action creator. It is used to handle the
asynchronous login process using a social login provider (in this case, Google). */
export const socialLogin = createAsyncThunk(
  "auth/social",
  async (credentials, thunkAPI) => {
    try {
      const response = await fetch(`${BASE_URL}/auth/google-social`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();
      if (response.status === 200 || response.ok) {
        toast.success("Login Successfully");
        return data;
      } else {
        toast.error("Network error");
        throw new Error("Network error"); // Reject the promise for non-specific errors
      }
    } catch (error) {
      console.error("An error occurred:", error);
      throw error; // Reject the promise for caught errors
    }
  }
);

export const OtpSent = createAsyncThunk(
  "auth/otpSent",
  async (credentials, thunkAPI) => {
    try {
      const otpResponse = await fetch(`${BASE_URL}/otp/email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: credentials.email }),
      });

      if (otpResponse.ok) {
        toast.success("Otp sent successfully");
      } else if (otpResponse.status === 409) {
        toast.error("User already exists. Please log in.");
        throw new Error("User already exists"); // Reject the promise for specific errors
      } else {
        toast.error("Some error occurred");
        throw new Error("Some error occurred"); // Reject the promise for non-specific errors
      }
    } catch (error) {
      console.error("An error occurred:", error);
      throw error; // Reject the promise for caught errors
    }
  }
);

// export const confirmOtp = createAsyncThunk(
//   "auth/confirmOtp",
//   async (credentials, thunkAPI) => {
//     const response = await fetch(`${BASE_URL}/otp/email-verify`, {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({ email: credentials.email, otp: credentials.otp }),
//       credentials: "include",
//     });
//     if (response.ok) {
//       toast.success("Otp Verified Successfully");
//       const profileResponse = await fetch(`${BASE_URL}/auth/register`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({
//           email: credentials.email,
//           name: credentials.email,
//           password: credentials.password,
//         }),
//         credentials: "include",
//       });
//       const profileData = await response.json();
//       if (profileResponse.status === 201 || profileResponse.status === 200) {
//         toast.success("User Logged In");
//         return profileData;
//       } else {
//         toast.error("Some error ocurred");
//       }
//     } else if (response.status === 401) {
//       toast.error("Incorrect Otp");
//     } else {
//       toast.error("Some error occurred");
//     }
//   }
// );
export const confirmOtp = createAsyncThunk(
  "auth/confirmOtp",
  async (credentials, thunkAPI) => {
    try {
      const response = await fetch(`${BASE_URL}/otp/email-verify`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: credentials.email,
          otp: credentials.otp,
        }),
      });

      if (response.ok) {
        toast.success("Otp Verified Successfully");

        const profileResponse = await fetch(`${BASE_URL}/auth/register`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: credentials.email,
            name: credentials.email,
            password: credentials.password,
          }),
        });

        if (profileResponse.status === 201 || profileResponse.status === 200) {
          toast.success("User Logged In");
          const profileData = await profileResponse.json();
          return profileData;
        } else {
          throw new Error("Some error occurred");
        }
      } else if (response.status === 401) {
        throw new Error("Incorrect Otp");
      } else {
        throw new Error("Some error occurred");
      }
    } catch (error) {
      // Handle errors and show toast messages here
      toast.error(error.message);
      throw error; // Reject the promise with the caught error
    }
  }
);

// export const uploadImage = createAsyncThunk(
//   "profile/uploadImage",
//   async (credentials, thunkAPI) => {
//     try {
//       const response = await fetch(
//         `https://maangler-backend.vercel.app/user/upload-profile-image`,
//         {
//           method: "POST",
//           headers: {
//             Authorization: `Token ${thunkAPI.getState().auth.token}`,
//             // "Content-Type": "multipart/form-data",
//           },
//           body: credentials,
//         }
//       );
//       console.log("response", response);
//     } catch (error) {
//       toast.error(error.message);
//       throw error;
//     }
//   }
// );
export const uploadImage = createAsyncThunk(
  "profile/uploadImage",
  async (credentials, thunkAPI) => {
    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dbjn5gkqr/image/upload`,
        {
          method: "POST",
          // headers: {
          //   Authorization: `Token ${thunkAPI.getState().auth.token}`,
          //   // "Content-Type": "multipart/form-data",
          // },
          body: credentials.formData,
        }
      );
      const data = await response.json();
      if (response.ok) {
        thunkAPI.dispatch(
          putUserProfile({
            ...credentials.data,
            account: { ...credentials.data.account, profileImage: data.url },
          })
        );
      } else {
        throw new Error(data?.error);
      }
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const putUserProfile = createAsyncThunk(
  "profile/putData",
  async (credentials, thunkAPI) => {
    try {
      const response = await fetch(`${BASE_URL}/user/profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${thunkAPI.getState().auth.token}`,
        },
        body: JSON.stringify(credentials),
      });
      if (response.ok) {
        toast.success("Updated Successfully");
        return credentials;
      } else {
        throw new Error("Update Failed");
      }
    } catch (error) {
      // toast.error(error.message);
      console.log(error, "error..........");
      throw error; // Reject the promise with the caught error
    }
  }
);

export const getProfileData = createAsyncThunk(
  "profile/getData",
  async (credentials, thunkAPI) => {
    try {
      const response = await fetch(`${BASE_URL}/user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${thunkAPI.getState().auth.token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        throw new Error(data?.error);
      }
    } catch (error) {
      toast.error(error.message);
      throw error; // Reject the promise with the caught error
    }
  }
);
